import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        
        <p className="copyright">
         Built with ❤️ by <a href="https://www.facebook.com/gidno.ukraine">NGO Gidno</a>, design: <a href="http://gatsby-dimension.surge.sh">Gatsby.js theme</a> 
        </p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
